module.exports = {
  getRedirectionToManageNewObject: (objectType) => {
    var redirectTo = "manage-";
    if (objectType == "smartPhones" || objectType == "tablettes")
      return redirectTo + "mobileDevice";
    else return redirectTo + objectType;
  },

  getRedirectionToManageStatesProduct: (objectType) => {
    var redirectTo = "states-";
    if (objectType == "smartPhones" || objectType == "tablettes")
      return redirectTo + "mobileDevice";
    else return redirectTo + objectType;
  },
  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  },
  isConfigsEmpty: (configs) => {
    var isConfigsEmpty = true;

    // il faut qu'il ai au moins une configuration
    if (typeof configs !== "undefined" && configs.length > 0) {
      for (var count = 0; count < configs.length; count++) {
        let element = configs[count];

        if (!element.color) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }

        if (!element.prices == "undefined" || element.prices.length == 0) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }

        if (
          typeof element.images == "undefined" ||
          element.images.length == 0
        ) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }
      }
    }

    return isConfigsEmpty;
  },
  isConfigsImagesAndColorsEmpty: (configs) => {
    var isConfigsEmpty = true;

    // il faut qu'il ai au moins une configuration images et couleurs
    if (typeof configs !== "undefined" && configs.length > 0) {
      for (var count = 0; count < configs.length; count++) {
        let element = configs[count];

        if (!element.color) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }

        if (
          typeof element.images == "undefined" ||
          element.images.length == 0
        ) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }
      }
    }

    return isConfigsEmpty;
  },

  isConfigsColorsEmpty: (configs) => {
    var isConfigsEmpty = true;

    // il faut qu'il ai au moins une configuration couleurs
    if (typeof configs !== "undefined" && configs.length > 0) {
      for (var count = 0; count < configs.length; count++) {
        let element = configs[count];

        if (!element.color) {
          isConfigsEmpty = true;
          break;
        } else {
          isConfigsEmpty = false;
        }
      }
    }

    return isConfigsEmpty;
  },

  getKeyByValue: (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  },
  formatDate: (date) => {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  },
  isNullOrEmptyOrSpaces: (string) => {
    return (
      string === null ||
      string.match(/^ *$/) !== null ||
      string === "undefined" ||
      string === "null"
    );
  },
  customSortDataTable: (items, sortBy, sortDesc) => {
    if (sortBy.length === 1 && sortDesc.length === 1) {
      return items.sort((a, b) => {
        const sortA = a[sortBy[0]];
        const sortB = b[sortBy[0]];

        if (sortBy == "date") {
          if (!sortDesc[0]) {
            return new Date(sortB) - new Date(sortA);
          } else {
            return new Date(sortA) - new Date(sortB);
          }
        } else {
          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        }
      });
    }
  },
  getItemsPerPageForDataTable: (items, itemsPerPage, page) => {
    if (itemsPerPage == -1) {
      return items.slice(
        (page - 1) * this.totalProductsCount,
        page * this.totalProductsCount
      );
    } else {
      return items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    }
  },

  /******** convertImagePathToFile  ****************/

  convertImagePathToFile(element) {
    var byteString = atob(element.path.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ia], { type: "image/jpeg" });
    return new File([blob], element.name);
  },
  /********  CapitalizeFirstLetter  ****************/

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  minimizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },

  getDifference(a, b) {
    return a.filter((element) => {
      return !b.includes(element);
    });
  },

  getFields(input, field) {
    var output = [];
    for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
  },
  // get Category Name  From Map
  getassignedCategoryName(category, map) {
    var result = [];
    for (var i = 0; i < category.length; i++) {
      result[i] = map.get(category[i]);
    }

    return result;
  },
  getassignedSubCategoryid(category) {
    var result = [];
    for (var i = 0; i < category.length; i++) {
      result[i] = category[i].id;
    }
    return result;
  },
  // get Category id From Map
  getassignedCategoryid(category, map) {
    var result = [];
    const inv = new Map(Array.from(map, (a) => a.reverse()));
    for (var i = 0; i < category.length; i++) {
      result[i] = inv.get(category[i]);
    }

    return result;
  },
  // Nom État du paiement
  getPaymentState(PAYMENTSTATE) {
    switch (PAYMENTSTATE) {
      case "CREE":
        return {
          valeur: "CREE",
          text: PAYMENTSTATE,
          color: "color: #2fbccd",
          backgroundColor: "background-color: rgba(47, 188, 205, 0.3)",
        };
      case "VALIDE":
        return {
          text: PAYMENTSTATE,
          color: "color: #7750a0",
          backgroundColor: "background-color: rgba(119, 80, 160, 0.3)",
        };
      case "ANNULE":
        return {
          text: PAYMENTSTATE,
          color: "color: #c4202c",
          backgroundColor: "background-color: rgba(196, 32, 44, 0.3)",
        };
      case "FINALISE":
        return {
          text: PAYMENTSTATE,
          color: "color: #178642",
          backgroundColor: "background-color: rgba(23, 134, 66, 0.3)",
        };
      case "REMBOURSE":
        return {
          text: PAYMENTSTATE,
          color: "color: #f06ba8",
          backgroundColor: "background-color: rgba(240, 107, 168, 0.3)",
        };
      default:
        return {
          text: PAYMENTSTATE,
        };
    }
  },
  // Statut de la commande
  getOrderState(orderState) {
    switch (orderState) {
      case "CREE":
        return {
          valeur: "CREE",
          text: orderState,
          color: "color: #2fbccd",
          backgroundColor: "background-color: rgba(47, 188, 205, 0.3)",
        };
      case "VALIDEE":
        return {
          text: orderState,
          color: "color: #7750a0",
          backgroundColor: "background-color: rgba(119, 80, 160, 0.3)",
        };
      case "EN_COURS_DE_PREPARATION":
        return {
          valeur: "EN_COURS_DE_PREPARATION",
          text: orderState,
          color: "color: #f68c1f",
          backgroundColor: "background-color: rgba(246, 140, 31, 0.1)",
        };
      case "EN_ATTENTE_DE_LIVRAISON":
        return {
          valeur: "EN_ATTENTE_DE_LIVRAISON",
          text: orderState,
          color: "color: #51b848",
          backgroundColor: "background-color: rgba(81, 184, 72, 0.1)",
        };
      case "EN_COURS_DE_LIVRAISON":
        return {
          text: orderState,
          color: "color: #7750a0",
          backgroundColor: "background-color: rgba(119, 80, 160, 0.1)",
        };
      case "RETOURNEE":
        return {
          text: orderState,
          color: "color: #f06ba8",
          backgroundColor: "background-color: rgba(240, 107, 168, 0.1)",
        };
      case "LIVREE":
        return {
          text: orderState,
          color: "color: #178642",
          backgroundColor: "background-color: rgba(23, 134, 66, 0.1)",
        };
      case "ANNULEE":
        return {
          text: orderState,
          color: "color: #c4202c",
          backgroundColor: "background-color: rgba(196, 32, 44, 0.3)",
        };
      default:
        return {
          text: orderState,
        };
    }
  },
  // Statut de la livraison
  getDeliveryState(deliveryState) {
    switch (deliveryState) {
      case "VALIDEE":
        return {
          text: deliveryState,
          color: "color: #2fbccd",
          backgroundColor: "background-color: rgba(47, 188, 205, 0.3)",
        };
      case "LIVRAISON_EN_COURS":
        return {
          text: deliveryState,
          color: "color: #7750a0",
          backgroundColor: "background-color: rgba(119, 80, 160, 0.3)",
        };
      case "TERMINEE":
        return {
          text: deliveryState,
          color: "color: #178642",
          backgroundColor: "background-color: rgba(23, 134, 66, 0.3)",
        };
      case "ANNULEE":
        return {
          text: deliveryState,
          color: "color: #c4202c",
          backgroundColor: "background-color: rgba(196, 32, 44, 0.1)",
        };
      default:
        return {
          text: deliveryState,
        };
    }
  },

  // Mode de paiement
  getPaymentMode(paymentMode) {
    switch (paymentMode) {
      case "CASH":
        return {
          text: paymentMode,
          color: "color: #51b848",
          backgroundColor: "background-color: rgba(81, 184, 72, 0.3)",
        };
      case "CB":
        return {
          text: paymentMode,
          color: "color: #2fbccd",
          backgroundColor: "background-color: rgba(47, 188, 205, 0.3)",
        };
      default:
        return {
          text: paymentMode,
        };
    }
  },
  //Status de la demande
  getDemandeState(DEMANDESTATE) {
    switch (DEMANDESTATE) {
      case "INVALIDE":
        return {
          text: DEMANDESTATE,
          color: " color: #FFFFFF",
          backgroundColor: "background-color: rgba(244,67,54)",
        };
      case "VALIDE":
        return {
          text: DEMANDESTATE,
          color: "color: #FFFFFF",
          backgroundColor: "background-color: rgba(0, 128, 0)",
        };
      case "EN_COURS_DE_VALIDATION":
        return {
          text: DEMANDESTATE,
          color: "color: #FFFFFF",
          backgroundColor: "background-color: rgba(246, 140, 31)",
        };
      default:
        return {
          text: DEMANDESTATE,
        };
    }
  },
  adjustForTimezone(date) {
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  },
};
